import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import flash from "../../images/article1.jpg";
import manoj from "../../images/Manoj-op.png";
import imgflash from "../../images/mbp.png";
import IntroBanner from "../../components/IntroBanner";

const PosttestIot = (props) => {
  return (
    <Layout bodyClass="page-blog flash">
      <Seo
        title="Saving Treasures in Drowning Flash"
        description="Learn how iTelaSoft saved its treasured Flash content using HTML5/CSS."
        image={flash}
      />
      <IntroBanner
        title="Saving Treasures in Drowning Flash"
        by="By Manoj Fernando"
        image={flash}
      />
      <div className="content">
        <div className="container pt-4 pt-md-4">
          <div className="row justify-content-start">
            <div className="col-12 col-md-12">
              <div className="content-body">
                <div className="content">
                  <h3>Modernising Flash-based legacy content to HTML5</h3>
                  <p>
                    The fate of Flash was sealed after Steve Jobs figured Flash
                    is straining the tiny processors in the first-generation iOS
                    devices and mercilessly draining precious battery power. In
                    fact, what killed Flash was Apple’s decision not to support
                    it on iOS, combined with iOS’s immense popularity and the
                    lucrative demographics of iOS users. Eventually, Adobe
                    accepted Flash’s demise!
                  </p>
                  <p>
                    {" "}
                    For a long time, content creators loved and embraced Flash
                    as the number one choice of building rich and interactive
                    experience on the web. Millions of content assets were built
                    for e-Learning, Interactive documentation, multimedia
                    illustrations, and countless other use cases. All of a
                    sudden large part of internet users were cut off from an
                    enormous stockpile of valuable content. Then, many
                    businesses largely depend on providing content services were
                    forced to figure out how to make their content working on
                    modern devices again.
                  </p>
                  <p>
                    At iTelaSoft, we are working with a number of eLearning
                    providers who provide interactive learning experiences
                    around the world. As part of our work with them, one
                    challenge we faced was how to modernise their Adobe
                    Flash-based legacy content. Obviously, the easy technology
                    answer was “HTML5/CSS3”, but the real journey was a bit
                    trickier than that!
                  </p>

                  <h4>What is “HTML5/CSS3”? Can they replace Flash?</h4>
                  <p>
                    Well, HTML5 and CSS3 are the baseline standards that are
                    implemented by most of the recognized web browsers by all
                    vendors. This means, as long as we follow those standards,
                    it is expected that something we want to show on the browser
                    will appear in the same way in every OS, every device. These
                    standards not only allow displaying documents on the browser
                    but also allow rich graphics and media to be rendered on the
                    browser canvas. In addition to that, using JavaScript we can
                    control what’s drawn on the browser. That’s exactly the
                    capability we relied on Flash for, and now we have an
                    alternative!
                  </p>

                  <h4>So, how do we convert Flash Content to HTML5/CSS3?</h4>
                  <p>
                    There is automated tooling available (by Adobe and third
                    parties) to convert existing Flash-based content to
                    HTML5/CSS3. The caveat is, you need to have the original
                    source files of Flash content to achieve a reasonable
                    conversion. Unfortunately, many businesses do not possess
                    original Flash source files and multimedia assets as opposed
                    to compiled Flash packages.
                  </p>
                  <p>
                    If an automatic conversion is not possible, then the other
                    option is re-development of content with side-by-side
                    duplication of Flash content. Needless to say, this is a
                    very laborious and expensive way of solving the problem.
                  </p>
                  <p>
                    But, there is a middle path possible in many cases! When it
                    comes to mass Flash to HTML5 conversions, often there are
                    two factors to be considered to make these migrations
                    economical.
                  </p>
                  <ol>
                    <li>
                      Metadata-driven content – In many large content bases, the
                      Flash-based content is driven by data sources (text,
                      questions, pictures, videos, animation rules etc.). This
                      opens the opportunity to automate.
                    </li>
                    <li>
                      Similarities – In many large content bases, there are
                      quite a few similarities within the different content
                      packages. This opens the opportunity to reuse.
                    </li>
                  </ol>
                  <p>
                    iTelaSoft developed its own Content Modernisation Framework
                    leveraging the above two attributes into an advantage. We
                    used a four-step approach for taming content migration
                    problems.
                  </p>
                  <ol>
                    <li>
                      Content Analysis – Identification of digital assets to be
                      recovered from Flash and detecting similarities
                    </li>
                    <li>
                      Planning – Estimating the amount of automation possible
                      and manual effort required
                    </li>
                    <li>
                      Automation – Automation of asset recovery, metadata-driven
                      rendering, and A/B testing
                    </li>
                    <li>
                      Conversion – Executing the conversion pipeline which is a
                      combination of automation plus manual effort (of coding,
                      configuring and testing)
                    </li>
                  </ol>
                  <div className="row">
                    <div className="col-12">
                      <div className="fl-img">
                        <img className="img-fluid" alt="flash" src={imgflash} />
                      </div>
                    </div>
                  </div>

                  <h4>Tech behind the framework</h4>
                  <p>
                    A significant part of the “new development” involves
                    building different “shells” that mimic particular types of
                    content and behavior that was already built with Flash.
                    Usually, a content migration with thousands of content
                    packages might require a few dozen shells to be developed,
                    depending on the degree of similarity in the content. We use
                    two framework types to build shells depending on the need.
                  </p>
                  <p>
                    –{" "}
                    <span className="highlight-link">
                      <a href="https://phaser.io/" target="_blank">
                        Phaser Framework{" "}
                      </a>
                    </span>
                    : This is a JavaScript framework optimized for 2D graphics
                    rendering on the browser. It leverages HTML5 Canvas and
                    ideal for implementing complex “game like” animations and
                    behaviors. It has a reasonably fast learning curve that
                    allows JavaScript developers to manipulate canvas at a
                    higher level of abstraction.
                  </p>
                  <p>
                    –{" "}
                    <span className="highlight-link">
                      <a href="https://github.com/svgdotjs" target="_blank">
                        SVG.Js
                      </a>
                    </span>{" "}
                    : This is a very lightweight low-level library for
                    manipulating and animating SVG based graphics (part of the
                    HTML5 specification) on the browser. Being a low-level
                    framework, it has a much steeper learning curve. But it’s
                    much leaner on processing power and saps little battery
                    power, making it a much better choice for mobile-based
                    content consumption.
                  </p>
                  <p>
                    Our shell development is based on{" "}
                    <span className="highlight-link">
                      <a href="https://www.typescriptlang.org/" target="_blank">
                        TypeScript
                      </a>
                    </span>
                    . It helped us to get developers from OOP background ramped
                    up faster into the projects. Also, edit-time and
                    compile-time type checking saved us a lot from the usual
                    late discovery of defects, common in JavaScript programming.
                  </p>
                  <p>
                    In our conversion pipeline, “Digital Asset Extraction” was
                    performed by a home-brewed toolset, that picks Flash
                    embedded assets like images, sounds, and video segments.
                    Part of the extraction process includes cleaning, scaling
                    and format conversions, so that the assets are compatible
                    with renderers and codecs natively supported in HTML5
                    compliant browsers. When certain assets were not in desired
                    quality, manual correction or recreation was also necessary.
                  </p>
                  <p>
                    Finally, a bundling process was involved to build HTML5
                    based content packages and deploy them. The testing process
                    (a lot of it manual but in part automated) is the final
                    validation of the newly migrated package on how faithfully
                    it mirrors the original behavior of the Flash-based content.
                  </p>
                  <p>
                    When framework building, the HTML5 feature compatibility
                    matrix in{" "}
                    <span className="highlight-link">
                      <a
                        href="https://mobilehtml5.org"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        https://mobilehtml5.org
                      </a>
                    </span>{" "}
                    was a handy tool.
                  </p>

                  <h4>So, what are the lessons learnt?</h4>
                  <ol>
                    <li>
                      Find your original Flash sources! – If original flash
                      source files are available, the degree of automation is
                      better, reducing the overall project cost. Unfortunately,
                      it is too late to talk about this now, but originals are
                      not archived, or they are not handed over from the Flash
                      content developers in the first place!
                    </li>
                    <li>
                      Original Flash sources do not always work, since – (a)
                      Conversion tools do not support older Flash and
                      ActionScript versions (b) Conversion tools do not do the
                      conversion exactly as its original (this depends on the
                      way a Flash content is built) (c) A direct conversion
                      might not leverage the best of HTML5 features.
                    </li>
                    <li>
                      Use real devices for testing – You can use simulators to
                      test interim releases. Browser and device farms are really
                      great for widening the test coverage, but are slow. There
                      is nothing like using a number of actual devices for
                      testing and they are faster and allow deeper debugging.
                    </li>
                    <li>
                      Flash and HTML5 are two different beasts – There are
                      various differences in the way these technologies behave,
                      and you may observe differences in the behaviors (scaling,
                      text rendering, loading, caching). So, making a content
                      conversion perfectly identical to its original version is
                      a journey with diminishing returns. Finding the balance
                      between exact appearance and economy should be vital in a
                      content modernisation program.
                    </li>
                  </ol>
                  <p>
                    iTelaSoft assists ISVs and Digital Businesses for product
                    engineering from POC, to Production, to Long Term Support.
                    We are working with a series of eLearning providers
                    including{" "}
                    <span className="highlight-link">
                      <a
                        href="https://www.skwirk.com.au/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        Skwirk Education
                      </a>
                    </span>{" "}
                    and{" "}
                    <span className="highlight-link">
                      <a
                        href="https://www.futureschool.com/"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        FutureSchool
                      </a>
                    </span>{" "}
                    to evolve and support their platforms. In both cases,
                    iTelaSoft was involved in content modernisation, making a
                    significant impact on their business. This article reflects
                    some of the techniques and lessons based on those exercises.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="author mt-5">
        <div className="container">
          <div className="row wrap-auther-detail">
            <div className="author-image">
              <img alt="Manoj Fernando" src={manoj} />
            </div>
            <div className="d-flex align-items-center">
              <div className="ml-lg-3 ml-sm-0 detail">
                <h4>
                  <i>Manoj Fernando</i>
                </h4>
                <span>
                  <i>iTelaSoft Product Engineering Team</i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "article1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default PosttestIot;
